@import "~antd/dist/antd.css";
@import url(https://fonts.googleapis.com/css?family=Sacramento|Poppins:400,700);
.error-banner {
  position: fixed;
  width: 100%;
  top: 64px;
  left: 0;
  z-index: 99;
}

.ant-typography.ant-typography-secondary {
    color: #39395f;
}
.signup-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px;
  max-width: 900px;
  margin: 0 auto;
}
@media (max-width: 62em) {
  .signup-content {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.signup__form-header {
  padding-bottom: 20px;
}

.signup__form-title.ant-typography {
  color: #183a1d;
  margin-bottom: 5px;
}
.welcome__banner-content {
    justify-content: center;
    align-items: center;
    padding: 40px 0;
    max-width: 70vw;
    margin: 0 auto;
}

.welcome__banner-content-title {
    padding: 10vw 0 0 0;
}

.welcome__banner-content-title.ant-typography {
    font-family: 'Poppins';
    font-weight: 700;
    color: #fff;
    font-size: 10vw;
}
@media (min-width: 900px) {
    .welcome__banner-content-title.ant-typography {
        font-size: 100px;
    }
}

.welcome__banner-content-subtitle.ant-typography {
    font-family: 'Poppins';
    font-weight: 400;
    color: #fff;
    margin-bottom: 5px;
    margin-top: 0.8em;
    font-size: 3vw;
}
@media (min-width: 900px) {
    .welcome__banner-content-subtitle.ant-typography {
        font-size: 30px;
    }
}

button.subscribe-btn {
    color: #fff;
    background-color: #287a2c;
    font-family: 'Poppins';
    font-weight: 700;
    margin-top: 1em;
    border: 3px solid #287a2c;
    display: block;
}

.welcome__banner {
    min-height: 10vw;
    width: 100%;
    position: relative;
    background: url("img/bg_orig.jpeg") no-repeat center;
    background-size: cover;
    text-align: center;
}

.welcome__banner-content-text {
    font-size: 4vw;
    color: #fff;
    display: block;
    margin-top: 1.5em;
}

.welcome__main-content-block {
    background: #fefbe9;
    margin: 5px;
    padding: 1em;
    color: #183a1d;
}

.welcome__main-content-block-title.ant-typography {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 4vw;
    color: #183a1d;
}
@media (min-width: 900px) {
    .welcome__main-content-block-title.ant-typography {
        font-size: 40px;
    }
}


.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.not-found__description-title.ant-typography {
  display: block;
  padding-bottom: 10px;
  color: #1d226c;
  font-size: 22px;
  font-weight: 700;
}
.not-found__description-subtitle.ant-typography {
  color: #1d226c;
  font-size: 16px;
}
.not-found__cta {
  margin-top: 20px;
}
html {
  overflow: auto;
}
#root,
body,
html {
  height: 100%;
  width: 100%;
  background: #e1eedd;
}
#app {
  position: relative;
  background: #e1eedd;
  min-height: 100vh;
  max-width: 1280px;
  margin: 0 auto;
}
.app-skeleton {
  height: 100%;
  background: #fff;
  display: flex;
}
.app-skeleton__spin-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.app__affix-header {
  z-index: 99;
}
.app__affix-footer {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
@media (max-width: 36em) {
  .app__affix-footer {
    display: none;
  }
}
